import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Utils from '../utility/utility.js';
import { postApiCall } from "../service/PostApiCall";
import { PROJECT_ADDTASK_HELPER, DAILY_CLOCK_IN_OUT, DAILY_CLOCK_VIEW, DAILY_CLOCK_OUT, DAILY_CLOCK_IN_EDIT } from "../service/apiUrl";
import CustomModal from "../components/CustomModal.js";
import { STRING } from "../constants/string.js";
import TaskTracker from "../components/TaskTracker.js";

const Developer = (props) => {

  const [isSatWorking, setIsSatWorking] = useState({ value: "", yes: false, no: false, date: null });

  const [viewData, setViewData] = useState({});
  const [show, setShow] = useState(false);

  const [isBreak, setIsBreak] = useState(false);
  const [reason, setReason] = useState({ value: "", error: false });
  const [breakTime, setBreakTime] = useState({
    hours: '',
    minutes: '',
  });

  const [isUpdating, setUpdating] = useState(false);

  const signdata = useSelector((state) => state.signinReducers);
  const uid = signdata.data ? Number(signdata.data.id) : null;

  useEffect(() => {
    if (uid != null) {
      getClockData();
    }
  }, []);

  const getClockData = () => {
    postApiCall({ date: Utils.momentDate(new Date(), STRING.CLOCK_FORMATED_DATE) }, signdata?.data?.access_token, DAILY_CLOCK_VIEW)
      .then((result) => {
        setViewData(result)
      })
      .catch((error) => {

        console.error('CLOCK_VIEW', error);
        if (error.data.message != undefined) {
          Utils.toast("error", error.data.message);
        }

      })
  }

  useEffect(() => {
    if (!viewData?.break?.no_break_reason && viewData?.break?.break) {
      let breakTime = viewData?.break?.break?.split(":");
      setBreakTime({
        hours: breakTime[0],
        minutes: breakTime[1]
      })
    } else if (viewData?.break?.no_break_reason) {
      setIsBreak(true)
      setReason({ error: false, value: viewData?.break?.no_break_reason })
    }
    let totalWorkingHours = 0;
    viewData?.dailyTasks?.map(value => {
      totalWorkingHours += parseInt(value.working_hrs)
    })

  }, [viewData])


  const againClockIn = (e) => {
    e.preventDefault();
    const payLoad = {
      uid: uid,
      tasks: [],
      againClockIn: true,
    }

    postApiCall(payLoad, signdata?.data?.access_token, DAILY_CLOCK_IN_OUT)
      .then((result) => {
        getClockData()
      })
      .catch((error) => {

        console.error('USER_HELPER', error);
        if (error.data.message != undefined) {
          Utils.toast("error", error.data.message);
        }

      })
  }

  const SaveDailyClockInOut = async () => {
    const payLoad = {
      uid: uid,
      tasks: []
    }

    postApiCall(payLoad, signdata?.data?.access_token, DAILY_CLOCK_IN_OUT)
      .then((result) => {
        getClockData()
      })
      .catch((error) => {

        console.error('USER_HELPER', error);
        if (error.data.message != undefined) {
          Utils.toast("error", error.data.message);
        }

      })
  }

  const clockOut = (e) => {
    e.preventDefault();
    setShow(true);
  }

  const addBreak = () => {
    if (Utils.momentDate(new Date(), 'dddd') == 'Friday' && !viewData?.isSatWorking && isSatWorking.yes === false && isSatWorking.no === false) {
      Utils.toast("error", "Please select one option for saturday!");
      return
    }
    if ((isBreak && reason?.value?.length > 44) || breakTime.minutes) {
      setReason({ ...reason, error: false });
      const payLoad = {
        "break": `${(breakTime?.hours && !Utils.isEmpty(breakTime?.hours)) ? breakTime?.hours : '00'}:${(breakTime?.minutes && !Utils.isEmpty(breakTime?.minutes)) ? breakTime?.minutes : '00'}`,
        "no_break_reason": reason?.value,
        "sat_working": isSatWorking?.yes ? isSatWorking?.date : '',
      }

      postApiCall(payLoad, signdata?.data?.access_token, DAILY_CLOCK_OUT)
        .then((result) => {
          getClockData()
          window.location.reload()
          // location
        })
        .catch((error) => {

          console.error('clock out', error);
          if (error.data.message != undefined) {
            Utils.toast("error", error.data.message);
          }

        })
    } else {
      setReason({ ...reason, error: true });
    }
  }

  return (
    <>
      <div className={props?.data?.transtion === true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body dashboard developer-dashboard">
          <div className="row mt-5">
            {viewData?.announcements?.map((data, idx) => {
              return (<div className="col-md-12" key={idx}>
                <div className="alert alert-warning shadow" role="alert">
                  <h4 className="alert-heading">
                    <i className="fa fa-bullhorn" aria-hidden="true"></i>{" "}{data.title}</h4>
                  <div dangerouslySetInnerHTML={{ __html: data.description }} />
                </div>
              </div>);
            })}
            {viewData?.birthdays?.map((data, idx) => {
              return (<div className="col-md-12" key={idx}>
                <div className="alert alert-info shadow" role="alert">
                  <h4 className="alert-heading">
                    <i className="fa fa-bullhorn" aria-hidden="true"></i>{` Today is ${data.first_name} ${data.last_name}'s Birthday!`}</h4>
                  <div dangerouslySetInnerHTML={{ __html: data.description }} />
                </div>
              </div>);
            })}

            <div className="col-md-12">
              <div className="task-report-description">
                <h3>ClockIn for {Utils.getCurrentDate()}</h3>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mt-3 card-input">
                <div className="week-data">
                  <div className="calender-icon icon"><i style={{ width: '20px' }} className="fa fa-calendar-check-o"></i></div>
                  <div className="week-calculated">
                    <p className="text-small">You have worked</p>
                    <div className="text-center hours">
                      <p className="text-big">
                        {Utils.getHoursFromSeconds(viewData?.weekTime)}
                      </p>
                      <p className="text-small">HH:MM:SS</p>
                    </div>
                    <p className="text-small">This week</p>
                  </div>
                </div>
                <div className="today-data">
                  <div className="clock-icon icon"><i style={{ width: '20px' }} className="fa fa-clock-o"></i></div>
                  <div className="today-calculated">
                    <p className="text-small">You have worked</p>
                    <div className="text-center hours">
                      {
                        viewData?.tracker?.filter(val => !val.end_time)?.length === 0 ?
                          <p className="text-big">
                            {viewData?.todayTime && viewData?.todayTime > 0 ? Utils.getHoursFromSeconds(viewData?.todayTime) : Utils.getHoursFromSeconds(0)}
                          </p>
                          :
                          <TaskTracker startTime={viewData?.tracker} breakTime={viewData?.break} />
                      }
                      <p className="text-small">HH:MM:SS</p>
                    </div>
                    <p className="text-small">Today</p>
                  </div>
                </div>
                {
                  <div className="controls btn-multiple">
                    <div className="btn-group-vertical">
                      <button onClick={() => { SaveDailyClockInOut() }} type="button" className={viewData?.todayTime > 0 ? "button btn btn-success hide" : "button btn btn-success"}>Clock In</button>

                      {
                        viewData?.todayTime && viewData?.todayTime > 0 && !viewData?.tracker?.filter(val => !val.end_time)?.length ?
                          <button className="button btn btn-warning" onClick={(e) => againClockIn(e)}>Clock in</button>
                          :
                          <button className={viewData?.todayTime <= 0 ? "button btn btn-danger mt-2 hide" : "button btn btn-danger mt-2"} onClick={(e) => { clockOut(e) }}>Clock Out</button>
                      }
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal isShow={show} onHide={() => { setShow(false) }} heading="Please add break Information" >
        <div className="row">
          <div className="col-md-12">
            <div className="mb-0">
              <label>All Lunch break inside office is also treated as break.</label>
              {
                !isBreak ? <div className="col-md-12">
                  <div className="row ">
                    <div className="form-group mr-3">
                      <label htmlFor="hours" className="text-bold">Hours</label>
                      <select className="form-control w-7" id="hours" value={breakTime?.hours} onChange={(e) => {
                        setBreakTime({ ...breakTime, hours: e.target.value })
                      }}>
                        <option value={''}>Please select Hours</option>
                        {
                          new Array(5).fill('').map((val, idx) => {
                            const value = (idx + 1).toString().padStart(2, '0');
                            return <option key={idx} value={value}>{value}</option>
                          })
                        }
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="minutes" className="text-bold">Minutes</label>
                      <select className="form-control w-7" id="minutes" value={breakTime?.minutes} onChange={(e) => {
                        setBreakTime({ ...breakTime, minutes: e.target.value })
                      }}>
                        <option value={''}>Please select Minutes</option>
                        {
                          new Array(3).fill('').map((val, idx) => {
                            const value = (45 + idx * 5).toString().padStart(2, '0');
                            return <option key={idx} value={value}>{value}</option>
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
                  : ''
              }
              <div className="col-md-12">
                <div className="row ">
                  <div className="form-check">
                    <input checked={isBreak} className="form-check-input" type="checkbox" value="" id="break" onChange={(e) => { setIsBreak(e.target.checked) }} />
                    <label className="form-check-label" htmlFor="break">
                      No Break
                    </label>
                  </div>
                </div>
              </div>
              {
                isBreak ? <div className="col-md-12">
                  <div className="row ">
                    <div className="form-group w-75">
                      <label className="text-bold" htmlFor="reason">Reason <span className="text-danger"> (Minimum 45 characters)</span></label>
                      <textarea value={reason?.value} onChange={(e) => { setReason({ ...reason, value: e.target.value }) }} rows="3" id="brkcomments" name="brkcomments" className={`form-control ${reason?.error ? 'error' : ''}`}> </textarea>
                      <div id="characterLeft">Characters: {reason?.value?.trim()?.length ?? 0}</div>
                    </div>
                  </div>
                </div> : ''
              }
              {
                Utils.momentDate(new Date(), 'dddd') == 'Friday' && !viewData?.isSatWorking ?
                  <div className="row mx-0 mt-3">
                    {/* <div className="col-md-12"> */}
                    Are you working this Saturday?
                    <div className="form-check ml-2">
                      <input className="form-check-input" name="isSatWorking" type="checkbox" onChange={(e) => {
                        setIsSatWorking({
                          ...isSatWorking,
                          yes: e.target.checked,
                          no: e.target.checked === true && isSatWorking.no === true ? false : isSatWorking.no,
                          date: Utils.getLastSaturday()
                        })
                      }} value="yes" id="yes" checked={isSatWorking.yes} />
                      <label className="form-check-label" htmlFor="yes">
                        Yes
                      </label>
                    </div>
                    <div className="form-check ml-2">
                      <input className="form-check-input" name="isSatWorking" type="checkbox" onChange={(e) => {
                        setIsSatWorking({
                          ...isSatWorking,
                          yes: e.target.checked === true && isSatWorking.yes === true ? false : isSatWorking.yes,
                          no: e.target.checked,
                          date: null
                        })
                      }} value="no" id="no" checked={isSatWorking.no} />
                      <label className="form-check-label" htmlFor="no">
                        No
                      </label>
                    </div>
                    {/* </div> */}
                  </div>
                  :
                  <div></div>
              }
              <div className="modal-footer mt-3" style={{ clear: "both" }}>
                <div style={{ float: "right", height: "auto" }}>
                  <button type="input" name="submit" id="addbreak" onClick={addBreak} className="btn btn-success btn-icon mr-2"><i className="fa fa-check-square-o"></i> Add break</button>
                  <button type="button" onClick={() => { setShow(false) }} className="btn btn-secondary btn-icon" data-dismiss="modal"><i className="fa fa-times-circle-o"></i> Cancel</button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default Developer;
