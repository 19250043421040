import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Utils from '../../utility/utility.js';
import { postApiCall } from "../../service/PostApiCall";
import { PROJECT_ADDTASK_HELPER, DAILY_CLOCK_IN_OUT, DAILY_CLOCK_VIEW, DAILY_CLOCK_OUT, DAILY_CLOCK_IN_EDIT } from "../../service/apiUrl";
import Addtasks from "../../components/Addtasks";
import CustomModal from "../../components/CustomModal.js";
import { STRING } from "../../constants/string.js";
import TaskTracker from "../../components/TaskTracker.js";

const Developer = (props) => {

  const [isLessWork, setIsLessWork] = useState(false);

  const [isTaskHasError, setIsTaskHasError] = useState(false);
  const [isSatWorking, setIsSatWorking] = useState({ value:"", yes: false, no: false, date: null });

  const [viewData, setViewData] = useState({});
  const [isView, setIsView] = useState(false);
  const [taskContainerCount, setTaskContainerCount] = useState([]);
  const [projectDetails, setProjectDetails] = useState({});
  const [show, setShow] = useState(false);
  const [deleteConfirmationShow, setDeleteConfirmationShow] = useState({ status: false, value: null });

  const [isBreak, setIsBreak] = useState(false);
  const [reason, setReason] = useState({ value: "", error: false });
  const [breakTime, setBreakTime] = useState({
    hours: '',
    minutes: '',
  });


  const [tasks, setTasks] = useState([]);
  const [isUpdating, setUpdating] = useState(false);
  const [dropdowns, setDropdowns] = useState({
    projectsList: [],
    projectTrackersList: [],
    billingTypes: [],
    isOffline: [],
    clickupAccounts: [],
    errors: [],
  });

  const signdata = useSelector((state) => state.signinReducers);
  const uid = signdata.data ? Number(signdata.data.id) : null;

  useEffect(() => {

    if (uid != null) {
      postApiCall({ uid: uid }, signdata?.data?.access_token, PROJECT_ADDTASK_HELPER)
        .then((result) => {
          setProjectDetails(result)

        })
        .catch((error) => {
          console.error('USER_HELPER', error);
        })

      getClockData();
    }

  }, []);

  const getClockData = () => {
    postApiCall({ date: Utils.momentDate(new Date(), STRING.CLOCK_FORMATED_DATE) }, signdata?.data?.access_token, DAILY_CLOCK_VIEW)
      .then((result) => {
        setViewData(result)
      })
      .catch((error) => {

        console.error('CLOCK_VIEW', error);
        if (error.data.message != undefined) {
          Utils.toast("error", error.data.message);
        }

      })
  }

  useEffect(() => {
    if (!viewData?.break?.no_break_reason && viewData?.break?.break) {
      let breakTime = viewData?.break?.break?.split(":");
      setBreakTime({
        hours: breakTime[0],
        minutes: breakTime[1]
      })
    } else if (viewData?.break?.no_break_reason) {
      setIsBreak(true)
      setReason({ error: false, value: viewData?.break?.no_break_reason })
    }
    let totalWorkingHours = 0;
    viewData?.dailyTasks?.map(value => {
      totalWorkingHours += parseInt(value.working_hrs)
    })

    if (viewData?.dailyTasks?.length && totalWorkingHours < 9) {
      // setViewData({...viewData,isLessWork:true})
      setIsLessWork(true);
    } else {
      setIsLessWork(false);
    }

  }, [viewData])

  useEffect(() => {
    setDropdowns({ ...dropdowns, projectsList: projectDetails?.projects ?? [], clickupAccounts: projectDetails?.clickupAccounts ?? [] })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetails?.clickupAccounts, projectDetails?.projects])

  /**
   * If the project is re-selected or changed, then refresh or reset the billing dropdowns
   * 
   * @param {*} obj 
   * @returns 
   */
  const resetDropDowns = obj => {

    obj['billing_id'] = obj['billing_type'] = ''; //obj['task_description'] = '';
    return obj;

  }

  const handleChange = (e, idx) => {
    e.preventDefault()

    const updatedTasks = tasks.map((obj, i) => {
      if (idx == i) {

        obj[e.target.name] = e.target.value;
        //If the project is re-selected or changed, then refresh or reset the billing dropdowns
        if (e.target.name == 'project_id') {
          resetDropDowns(obj);
        }
        if (e.target.name === 'project_id') {
          const selectedProject = projectDetails?.projects?.filter(value => {
            return e.target.value == value?.id
          })
          var { projectTrackersList, billingTypes, isOffline } = dropdowns;

          if (selectedProject?.length) {


            projectTrackersList[i] = selectedProject?.[0]?.trackers;

            billingTypes[i] = projectDetails?.billing_type?.filter(value => {
              return value.value == selectedProject?.[0]?.billing_type;
            })

            isOffline[i] = selectedProject?.[0]?.is_offline;

          } else {
            projectTrackersList[i] = []
            billingTypes[i] = []
            isOffline[i] = ""
          }

          setDropdowns({ ...dropdowns, projectTrackersList: projectTrackersList, billingTypes: billingTypes, isOffline: isOffline });
        }
        if (e.target.name === "working_hrs" || e.target.name === "project_id" || e.target.name === "billing_type") {
          var { errors } = dropdowns;

          if (tasks?.[idx]?.project_id && tasks?.[idx]?.billing_type && tasks?.[idx]?.billing_type != "0") {
            const project = dropdowns?.projectsList?.filter(val => val.id == tasks?.[idx]?.project_id)?.[0];
            if ((project?.estimated_hour - project?.activity?.online) < tasks?.[idx]?.working_hrs) {
              errors[i] = { ...errors[i], working_hrs: true };
              setIsTaskHasError(true);
            } else {
              errors[i] = { ...errors[i], working_hrs: false };
              setIsTaskHasError(false);
            }
          } else if (tasks?.[idx]?.project_id && tasks?.[idx]?.billing_type && tasks?.[idx]?.billing_type == '0') {
            errors[i] = { ...errors[i], working_hrs: false };
            setIsTaskHasError(false);
          }
          setDropdowns({ ...dropdowns, errors: errors });

        }
      }
      return obj;
    });

    setTasks(updatedTasks);

  }

  const updateNewTaskContainer = () => {
    setTaskContainerCount([...taskContainerCount, 0]);
    if (tasks?.length) {
      setTasks([...tasks, { project_id: 0, billing_id: 0, billing_type: '', working_hrs: '', task_description: '' }]);
    } else {
      setTasks([{ project_id: 0, billing_id: 0, billing_type: '', working_hrs: '', task_description: '' }]);
    }

  }

  const closeTaskContainer = idx => {

    setDeleteConfirmationShow({ value: idx, status: true })

  }

  const deleteTaskContainer = () => {
    if (deleteConfirmationShow.value > -1 && deleteConfirmationShow.status) {
      taskContainerCount.splice(deleteConfirmationShow.value, 1);
      tasks.splice(deleteConfirmationShow.value, 1);
      setTaskContainerCount(taskContainerCount);
      setTasks(tasks);
      setUpdating(!isUpdating);
      setDeleteConfirmationShow({ value: null, status: false })
    }
  }

  const againClockIn = (e) => {
    e.preventDefault();
    const payLoad = {
      uid: uid,
      tasks: [],
      againClockIn: true,
    }
    // console.log('payLoad',payLoad);
    // return;

    postApiCall(payLoad, signdata?.data?.access_token, DAILY_CLOCK_IN_OUT)
      .then((result) => {
        getClockData()
      })
      .catch((error) => {

        console.error('USER_HELPER', error);
        if (error.data.message != undefined) {
          Utils.toast("error", error.data.message);
        }

      })
  }

  const SaveDailyClockInOut = async () => {

    if (validation() && !isLessWork && !isTaskHasError) {
      const payLoad = {
        uid: uid,
        tasks: tasks
      }
    //   console.log('payLoad 2',payLoad);
    // return;

      postApiCall(payLoad, signdata?.data?.access_token, DAILY_CLOCK_IN_OUT)
        .then((result) => {
          setTasks([])
          setTaskContainerCount([]);
          setIsView(false)
          getClockData()
        })
        .catch((error) => {

          console.error('USER_HELPER', error);
          if (error.data.message != undefined) {
            Utils.toast("error", error.data.message);
          }

        })
    } else if (isLessWork) {
      const payLoad = {
        uid: uid,
        dt_id: viewData?.dailyTasks?.[0]?.dt_id,
        tasks: tasks
      }
      postApiCall(payLoad, signdata?.data?.access_token, DAILY_CLOCK_IN_EDIT)
        .then((result) => {
          setTasks([])
          setTaskContainerCount([]);
          // setIsView(false)
          getClockData()
        })
        .catch((error) => {

          console.error('USER_HELPER', error);
          if (error.data.message != undefined) {
            Utils.toast("error", error.data.message);
          }

        })
    }

  }

  const validation = () => {
    let message = "";

    tasks?.map((value, i) => {
      if (Utils.isEmpty(value?.project_id)) {
        message = STRING.ENTER_PROJECT;
      }
      if (Utils.isEmpty(value?.billing_type)) {
        message = STRING.ENTER_BILLING_STATUS;
      }
      if (value?.billing_type != Utils.BILLING_STATUS_OFFLINE && Utils.isEmpty(value?.billing_id)) {
        message = STRING.ENTER_BILLING_ID;
      }
      if (Utils.isEmpty(value?.working_hrs)) {
        message = STRING.ENTER_WORKING_HOURS;
      }
      if (Utils.isEmpty(value?.task_description)) {
        message = STRING.ENTER_TASK_DESCRIPTION;
      }
    })
    if (message) {
      Utils.toast('error', message);
      return false;
    } else {
      return true;
    }
  }

  const clockOut = (e) => {
    e.preventDefault();
    setShow(true);
  }

  const editEntery = () => {
    if (viewData?.dailyTasks?.length) {
      if (!isView) {
        setTasks(viewData?.dailyTasks)
        setTaskContainerCount(new Array(viewData?.dailyTasks?.length).fill(0));

        viewData?.dailyTasks?.map((obj, i) => {
          const selectedProject = projectDetails?.projects?.filter(value => {
            return obj.project_id == value?.id
          })
          var { projectTrackersList, billingTypes, isOffline } = dropdowns;

          if (selectedProject?.length) {

            projectTrackersList[i] = selectedProject?.[0]?.trackers;

            billingTypes[i] = projectDetails?.billing_type?.filter(value => {
              return value.value == selectedProject?.[0]?.billing_type;
            })

            isOffline[i] = selectedProject?.[0]?.is_offline;

          } else {
            projectTrackersList[i] = []
            billingTypes[i] = []
            isOffline[i] = ""
          }

          setDropdowns({ ...dropdowns, projectTrackersList: projectTrackersList, billingTypes: billingTypes, isOffline: isOffline });
        })

        // setIsView(true)
      } else {
        setTasks([])
        setTaskContainerCount([]);
        // setIsView(false)
      }

    }
  }

  const viewTasks = () => {
    if (viewData?.dailyTasks?.length) {
      if (!isView) {
        setTasks(viewData?.dailyTasks)
        setTaskContainerCount(new Array(viewData?.dailyTasks?.length).fill(0));

        viewData?.dailyTasks?.map((obj, i) => {
          const selectedProject = projectDetails?.projects?.filter(value => {
            return obj.project_id == value?.id
          })
          var { projectTrackersList, billingTypes, isOffline } = dropdowns;

          if (selectedProject?.length) {

            projectTrackersList[i] = selectedProject?.[0]?.trackers;

            billingTypes[i] = projectDetails?.billing_type?.filter(value => {
              return value.value == selectedProject?.[0]?.billing_type;
            })

            isOffline[i] = selectedProject?.[0]?.is_offline;

          } else {
            projectTrackersList[i] = []
            billingTypes[i] = []
            isOffline[i] = ""
          }

          setDropdowns({ ...dropdowns, projectTrackersList: projectTrackersList, billingTypes: billingTypes, isOffline: isOffline });
        })

        setIsView(true)
      } else {
        setTasks([])
        setTaskContainerCount([]);
        setIsView(false)
      }

    }
  }

  const addBreak = () => {
    if(Utils.momentDate(new Date(), 'dddd') == 'Friday' && !viewData?.isSatWorking && isSatWorking.yes === false && isSatWorking.no === false){
      Utils.toast("error", "Please select one option for saturday!");
      return
    }
    if ((isBreak && reason?.value?.length > 44) || breakTime.minutes) {
      setReason({ ...reason, error: false });
      const payLoad = {
        "break": `${(breakTime?.hours && !Utils.isEmpty(breakTime?.hours)) ? breakTime?.hours : '00'}:${(breakTime?.minutes && !Utils.isEmpty(breakTime?.minutes)) ? breakTime?.minutes : '00'}`,
        "no_break_reason": reason?.value,
        "sat_working": isSatWorking?.yes ? isSatWorking?.date : '',
      }

      postApiCall(payLoad, signdata?.data?.access_token, DAILY_CLOCK_OUT)
        .then((result) => {
          // getClockData()
          // setShow(false)
          window.location.reload()
          // location
        })
        .catch((error) => {

          console.error('clock out', error);
          if (error.data.message != undefined) {
            Utils.toast("error", error.data.message);
          }

        })
    } else {
      setReason({ ...reason, error: true });
    }
  }

  return (
    <>
      <div className={props?.data?.transtion === true ? "contents" : "content"}>
        <div className="content-top-spacer"></div>
        <div className="color-body dashboard developer-dashboard">
          <div className="row mt-5">
            {viewData?.announcements?.map((data, idx) => {
              return (<div className="col-md-12" key={idx}>
                <div className="alert alert-warning shadow" role="alert">
                  <h4 className="alert-heading">
                    <i className="fa fa-bullhorn" aria-hidden="true"></i>{" "}{data.title}</h4>
                  <div dangerouslySetInnerHTML={{ __html: data.description }} />
                </div>
              </div>);
            })}
            {viewData?.birthdays?.map((data, idx) => {
              return (<div className="col-md-12" key={idx}>
                <div className="alert alert-info shadow" role="alert">
                  <h4 className="alert-heading">
                    <i className="fa fa-bullhorn" aria-hidden="true"></i>{` Today is ${data.first_name} ${data.last_name}'s Birthday!` }</h4>
                  <div dangerouslySetInnerHTML={{ __html: data.description }} />
                </div>
              </div>);
            })}
            {viewData?.alreadyUsedBillingId?.map((data, idx) => {
              return (<div className="col-md-12" key={idx}>
                <div className="alert alert-danger shadow" role="alert">
                  <span className="text-bold">{data?.map(val => val.name)?.join(', ')}</span> is also using <span className="text-bold">{data?.[0]?.BillingId}</span> ID. Please verify before using it.
                </div>
              </div>);
            })}
            {/* {
              true ?
                <div className="col-md-12">
                  <Tabs className="">
                    <TabList>
                      <Tab> <FontAwesomeIcon className="p-2 br-50" style={{
                        color:'#C9695E',
                        backgroundColor:'#FDE9E9'
                      }} icon={faBirthdayCake} /> <span className="pb-2" >Birthdays</span> </Tab>
                      <Tab> <FontAwesomeIcon className="p-2 br-50" style={{
                        color:'#FDE9E9',
                        backgroundColor:'#EFFCE8'
                      }} icon={faBirthdayCake} /> <span className="pb-2" >Work Anniversary</span></Tab>
                    </TabList>
                    <TabPanel></TabPanel>
                    <TabPanel></TabPanel>
                  </Tabs>
                </div>
                : ""
            } */}
            <div className="col-md-12">
              <div className="task-report-description">
                <h3>Add tasks for {Utils.getCurrentDate()}</h3>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mt-3 card-input">
                <div className="week-data">
                  <div className="calender-icon icon"><i style={{ width: '20px' }} className="fa fa-calendar-check-o"></i></div>
                  <div className="week-calculated">
                    <p className="text-small">You have worked</p>
                    <div className="text-center hours">
                      <p className="text-big">
                        {Utils.getHoursFromSeconds(viewData?.weekTime)}
                      </p>
                      <p className="text-small">HH:MM:SS</p>
                    </div>
                    <p className="text-small">This week</p>
                  </div>
                </div>
                <div className="today-data">
                  <div className="clock-icon icon"><i style={{ width: '20px' }} className="fa fa-clock-o"></i></div>
                  <div className="today-calculated">
                    <p className="text-small">You have worked</p>
                    <div className="text-center hours">
                      {
                        viewData?.tracker?.filter(val => !val.end_time)?.length === 0 ?
                          <p className="text-big">
                            {viewData?.todayTime && viewData?.todayTime > 0 ? Utils.getHoursFromSeconds(viewData?.todayTime) : Utils.getHoursFromSeconds(0)}
                          </p>
                          :
                          <TaskTracker startTime={viewData?.tracker} breakTime={viewData?.break} />
                      }
                      <p className="text-small">HH:MM:SS</p>
                    </div>
                    <p className="text-small">Today</p>
                  </div>
                </div>
                {
                  (viewData?.tracker?.filter(val => !val.end_time)?.length || (viewData?.todayTime && viewData?.todayTime > 0)) && !isLessWork ?
                    <div className="controls btn-multiple">
                      <div className="btn-group-vertical">
                        {
                          viewData?.todayTime && viewData?.todayTime > 0 && !viewData?.tracker?.filter(val => !val.end_time)?.length ?
                            <button className="button btn btn-danger" onClick={(e) => againClockIn(e)}>Clock in</button>
                            :
                            <button className="button btn btn-danger" onClick={(e) => { clockOut(e) }}>Clock Out</button>
                        }
                        <button className="button btn btn-info mt-2" onClick={viewTasks}>{isView ? 'Hide' : 'View'} Tasks</button>
                      </div>
                    </div> :
                    <div className="controls btn-single">
                      <button onClick={() => {
                        if (isLessWork) {
                          editEntery()
                        } else {
                          updateNewTaskContainer()
                        }
                      }} className="button btn btn-success">Add Tasks</button>
                    </div>
                }
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12">
              <div className="tasks-wrappers">
                {
                  // <RenderNewTaskContainer />
                  tasks?.map((val, i) => {
                    return (<Addtasks
                      on_close_container={() => closeTaskContainer(i)}
                      index={i}
                      key={i}
                      isView={isView}
                      dropdowns={dropdowns}
                      on_change={e => handleChange(e, i)}
                      task={tasks[i]}
                    />);
                  })
                }
              </div>
              {
                taskContainerCount.length > 0 && !isView ?
                  <div className="form-group text-right mt-3 mvb-3">
                    <button onClick={() => updateNewTaskContainer()} type="button" className="button btn btn-info">Add More Tasks</button>
                    <button disabled={isTaskHasError} onClick={() => { SaveDailyClockInOut() }} type="button" className="button btn btn-success ml-2">Clock In</button>
                  </div>
                  :
                  <></>
              }
            </div>
          </div>
        </div>
      </div>
      <CustomModal isShow={deleteConfirmationShow.status} onHide={() => { setDeleteConfirmationShow({ ...deleteConfirmationShow, status: false }) }} heading="Delete Task">
        <div className="row">
          <div className="col-md-12">
            <div className="mb-0">
              <label>Are you sure you want to delete it?</label>
              <div className="modal-footer mt-3" style={{ clear: "both" }}>
                <div style={{ float: "right", height: "auto" }}>
                  <button type="input" name="button" onClick={deleteTaskContainer} id="deleteTask" className="btn btn-danger btn-icon mr-2"><i className="fa fa-check-square-o"></i>Delete Task</button>
                  <button type="button" onClick={() => { setDeleteConfirmationShow({ ...deleteConfirmationShow, status: false }) }} className="btn btn-secondary btn-icon" data-dismiss="modal"><i className="fa fa-times-circle-o"></i> Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal isShow={show} onHide={() => { setShow(false) }} heading="Please add break Information" >
        <div className="row">
          <div className="col-md-12">
            <div className="mb-0">
              <label>All Lunch break inside office is also treated as break.</label>
              {
                !isBreak ? <div className="col-md-12">
                  <div className="row ">
                    <div className="form-group mr-3">
                      <label htmlFor="hours" className="text-bold">Hours</label>
                      <select className="form-control w-7" id="hours" value={breakTime?.hours} onChange={(e) => {
                        setBreakTime({ ...breakTime, hours: e.target.value })
                      }}>
                        <option value={''}>Please select Hours</option>
                        {
                          new Array(5).fill('').map((val, idx) => {
                            const value = (idx + 1).toString().padStart(2, '0');
                            return <option key={idx} value={value}>{value}</option>
                          })
                        }
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="minutes" className="text-bold">Minutes</label>
                      <select className="form-control w-7" id="minutes" value={breakTime?.minutes} onChange={(e) => {
                        setBreakTime({ ...breakTime, minutes: e.target.value })
                      }}>
                        <option value={''}>Please select Minutes</option>
                        {
                          new Array(3).fill('').map((val, idx) => {
                            const value = (45 + idx * 5).toString().padStart(2, '0');
                            return <option key={idx} value={value}>{value}</option>
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
                  : ''
              }
              <div className="col-md-12">
                <div className="row ">
                  <div className="form-check">
                    <input checked={isBreak} className="form-check-input" type="checkbox" value="" id="break" onChange={(e) => { setIsBreak(e.target.checked) }} />
                    <label className="form-check-label" htmlFor="break">
                      No Break
                    </label>
                  </div>
                </div>
              </div>
              {
                isBreak ? <div className="col-md-12">
                  <div className="row ">
                    <div className="form-group w-75">
                      <label className="text-bold" htmlFor="reason">Reason <span className="text-danger"> (Minimum 45 characters)</span></label>
                      <textarea value={reason?.value} onChange={(e) => { setReason({ ...reason, value: e.target.value }) }} rows="3" id="brkcomments" name="brkcomments" className={`form-control ${reason?.error ? 'error' : ''}`}> </textarea>
                      <div id="characterLeft">Characters: {reason?.value?.trim()?.length ?? 0}</div>
                    </div>
                  </div>
                </div> : ''
              }
              {
               Utils.momentDate(new Date(), 'dddd') == 'Friday' && !viewData?.isSatWorking ?
                  <div className="row mx-0 mt-3">
                    {/* <div className="col-md-12"> */}
                    Are you working this Saturday?
                    <div className="form-check ml-2">
                      <input className="form-check-input" name="isSatWorking" type="checkbox" onChange={(e) => {
                        setIsSatWorking({
                          ...isSatWorking,
                          yes:e.target.checked,
                          no: e.target.checked === true &&  isSatWorking.no === true ? false : isSatWorking.no,
                          date : Utils.getLastSaturday()
                        })
                      }} value="yes" id="yes" checked={isSatWorking.yes} />
                      <label className="form-check-label" htmlFor="yes">
                        Yes
                      </label>
                    </div>
                    <div className="form-check ml-2">
                      <input className="form-check-input" name="isSatWorking" type="checkbox" onChange={(e) => {
                        setIsSatWorking({
                          ...isSatWorking,
                          yes:e.target.checked === true &&  isSatWorking.yes === true ? false : isSatWorking.yes ,
                          no: e.target.checked,
                          date : null
                        })
                      }} value="no" id="no" checked={isSatWorking.no} />
                      <label className="form-check-label" htmlFor="no">
                        No
                      </label>
                    </div>
                    {/* </div> */}
                  </div>
                  :
                  <div></div>
              }
              <div className="modal-footer mt-3" style={{ clear: "both" }}>
                <div style={{ float: "right", height: "auto" }}>
                  <button type="input" name="submit" id="addbreak" onClick={addBreak} className="btn btn-success btn-icon mr-2"><i className="fa fa-check-square-o"></i> Add break</button>
                  <button type="button" onClick={() => { setShow(false) }} className="btn btn-secondary btn-icon" data-dismiss="modal"><i className="fa fa-times-circle-o"></i> Cancel</button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default Developer;
