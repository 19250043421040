import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { useNavigate, useParams } from 'react-router-dom'
import { postApiCall } from '../../service/PostApiCall'
import {
  ADMIN_LISTING,
  SUPPORT_CREATE,
  SUPPORT_UPDATE,
  SUPPORT_VIEW,
  USER_HELPER,
  USER_SUPPORT_CREATE,
  USER_SUPPORT_VIEW,
  USER_SUPPORT_UPDATE,
} from '../../service/apiUrl'
import Utils from '../../utility/utility'

const AddSupport = (props) => {
  const { id } = useParams()
  const userId = localStorage.getItem('_id')
  const navigate = useNavigate()
  const [adminOptions, setAdminOptions] = useState([])
  const [supportData, setSupportData] = useState({
    title: '',
    description: '',
    selectedValue: { value: '', label: '' },
    status: { value: '1', label: 'Pending' },
    reply: '',
  })

  const signData = useSelector((state) => state.signinReducers)
  const userRoles = signData?.data?.role
  const token = signData?.data?.access_token

  const handleAdminListing = async () => {
    try {
      const params = {}
      const URL_HANDLER = userRoles === 1 ? ADMIN_LISTING : USER_HELPER
      const response = await postApiCall(params, token, URL_HANDLER)
      if (response?.admins) {
        setAdminOptions(response?.admins)
      }
    } catch (error) {
      console.error('Error in fetching admin users', error)
    }
  }

  useEffect(() => {
    handleAdminListing()
  }, [])

  const checkStatus = (status) => {
    switch (status) {
      case '1':
        return 'Pending'
      case '2':
        return 'Open'
      case '3':
        return 'Hold'
      case '4':
        return 'Closed'
      default:
        return ''
    }
  }

  const handleData = async () => {
    try {
      const params = { id }
      const CHECK_URL = userRoles === 1 ? SUPPORT_VIEW : USER_SUPPORT_VIEW
      const response = await postApiCall(params, token, CHECK_URL)
      if (response) {
        setSupportData({
          title: response.title,
          description: response.description,
          selectedValue: {
            value: response.assigned_to?.id,
            label: response.assigned_to?.name,
          },
          status: {
            value: response?.status,
            label: checkStatus(response?.status),
          },
          reply: response.reply || '',
        })
      }
    } catch (error) {
      console.error('Error in fetching support data', error)
    }
  }

  useEffect(() => {
    if (id) {
      handleData()
    }
  }, [id, adminOptions])

  const handleChangeEvent = (event) => {
    const { name, value } = event.target
    setSupportData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSelectChange = (selectedOption, action) => {
    setSupportData((prevState) => ({
      ...prevState,
      [action.name]: selectedOption,
    }))
  }

  const handleDescriptionChange = (event, editor) => {
    const data = editor.getData()
    setSupportData((prevState) => ({
      ...prevState,
      description: data,
    }))
  }

  const handleReplyChange = (event, editor) => {
    const data = editor.getData()
    setSupportData((prevState) => ({
      ...prevState,
      reply: data,
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      if (id) {
        const params = {
          id,
          title: supportData.title,
          description: supportData.description,
          assigned_to: supportData.selectedValue?.value,
          status: supportData.status?.value,
          updated_by: userId,
          reply: supportData.reply,
        }

        const CHECK_URL = userRoles === 1 ? SUPPORT_UPDATE : USER_SUPPORT_UPDATE

        const responseData = await postApiCall(params, token, CHECK_URL)
        if (responseData) {
          Utils.toast('success', 'Support ticket updated successfully!')
          navigate('/view-support')
        }
      } else {
        const params = {
          title: supportData.title,
          description: supportData.description,
          assigned_to: supportData.selectedValue?.value,
          status: supportData.status?.value,
          created_by: userId,
        }
        const userCreate =
          userRoles === 1 ? SUPPORT_CREATE : USER_SUPPORT_CREATE
        const responseData = await postApiCall(params, token, userCreate)
        if (responseData) {
          Utils.toast('success', 'Support ticket created successfully!')
          navigate('/view-support')
        }
      }
    } catch (error) {
      console.error('Error in submitting form', error)
    }
  }

  return (
    <div className={props?.data?.transtion === true ? 'contents' : 'content'}>
      <div className="card-input mt-5 mb-2" style={{ width: '50%' }}>
        <div className="col-md-12">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12 mt-2">
                <div className="form-group">
                  <label htmlFor="admin">Admin</label>
                  <Select
                    options={adminOptions}
                    placeholder="Please Select An Admin"
                    onChange={(selectedOption) =>
                      handleSelectChange(selectedOption, {
                        name: 'selectedValue',
                      })
                    }
                    value={supportData.selectedValue}
                  />
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <div className="form-group">
                  <label htmlFor="title">Ticket Title</label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    placeholder="Please enter ticket title..."
                    value={supportData.title}
                    onChange={handleChangeEvent}
                  />
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <div className="form-group">
                  <label htmlFor="description">Ticket Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={supportData.description}
                    onChange={handleDescriptionChange}
                    config={{
                      placeholder: 'Please enter the description here...',
                      toolbar: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'blockQuote',
                        'link',
                        'numberedList',
                        'bulletedList',
                        'imageUpload',
                        'insertTable',
                        'tableColumn',
                        'tableRow',
                        'mergeTableCells',
                        'mediaEmbed',
                        '|',
                        'undo',
                        'redo',
                      ],
                    }}
                  />
                </div>
              </div>
              {id && userRoles !== 5 && (
                <div className="col-md-12 mt-2">
                  <div className="form-group">
                    <label htmlFor="status">Status</label>
                    <Select
                      options={[
                        { value: '1', label: 'Pending' },
                        { value: '2', label: 'Open' },
                        { value: '3', label: 'Hold' },
                        { value: '4', label: 'Closed' },
                      ]}
                      placeholder="Please Select Status"
                      onChange={(selectedOption) =>
                        handleSelectChange(selectedOption, { name: 'status' })
                      }
                      value={supportData.status}
                    />
                  </div>
                </div>
              )}
              {id && (
                <div className="col-md-12 mt-2">
                  <div className="form-group">
                    <label htmlFor="reply">Ticket Reply</label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={supportData.reply}
                      onChange={handleReplyChange}
                      config={{
                        placeholder: 'Please reply here...',
                        toolbar: [
                          'heading',
                          '|',
                          'bold',
                          'italic',
                          'blockQuote',
                          'link',
                          'numberedList',
                          'bulletedList',
                          'imageUpload',
                          'insertTable',
                          'tableColumn',
                          'tableRow',
                          'mergeTableCells',
                          'mediaEmbed',
                          '|',
                          'undo',
                          'redo',
                        ],
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="col-md-12">
                <div className="row mb-5 ml-2 mt-3">
                  <div className="">
                    <button type="submit" className="create-button">
                      {id ? 'Update' : 'Submit'}
                    </button>
                  </div>
                  <div className="ml-2">
                    <button
                      type="button"
                      className="create-button1 btn-info"
                      onClick={() => navigate('/view-support')}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddSupport
